//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    queryParam: {
      type: Object,
      required: true
    }
  },

  computed: {
    acitveClearButton() {
      const { title, slug } = this.queryParam;
      return title || slug;
    }
  },

  methods: {
    changeTitle(value) {
      this.$emit('changeTitle', value);
      this.handleSearch();
    },
    changeSlug(value) {
      this.$emit('changeSlug', value);
      this.handleSearch();
    },
    handleSearch() {
      this.$emit('handleSearch');
    },
    handleReset() {
      this.$emit('handleReset');
    },
    toggleView() {
      const mode = this.queryParam.mode === 'card' ? 'grid' : 'card';
      this.$emit('toggleView', mode);
    }
  }
};

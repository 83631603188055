var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { type: "flex", align: "middle", justify: "space-between" } },
    [
      _c(
        "a-col",
        [
          _vm._t("left"),
          !!_vm.$slots.left
            ? _c("a-divider", {
                staticStyle: { "font-size": "28px" },
                attrs: { type: "vertical" }
              })
            : _vm._e(),
          _c("a-input-search", {
            staticClass: "mr-xs",
            staticStyle: { width: "200px" },
            attrs: { "allow-clear": "", placeholder: _vm.$t("Page name") },
            on: { search: _vm.changeTitle },
            model: {
              value: _vm.queryParam.title,
              callback: function($$v) {
                _vm.$set(_vm.queryParam, "title", $$v)
              },
              expression: "queryParam.title"
            }
          }),
          _c("a-input-search", {
            staticStyle: { width: "200px" },
            attrs: {
              "allow-clear": "",
              placeholder: _vm.$t("project.grid.Path")
            },
            on: { search: _vm.changeSlug },
            model: {
              value: _vm.queryParam.slug,
              callback: function($$v) {
                _vm.$set(_vm.queryParam, "slug", $$v)
              },
              expression: "queryParam.slug"
            }
          }),
          _c("a-divider", {
            staticStyle: { "font-size": "28px" },
            attrs: { type: "vertical" }
          }),
          _c(
            "a-button",
            {
              attrs: {
                icon: "close",
                type: "danger",
                disabled: !_vm.acitveClearButton
              },
              on: { click: _vm.handleReset }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Reset")) + " ")]
          )
        ],
        2
      ),
      _c("a-col", [
        _c(
          "div",
          { staticClass: "content-end", staticStyle: { width: "100%" } },
          [_vm._t("right")],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }